<template>
  <v-form autocomplete="off" @submit.prevent="$emit('submit')">
    <v-text-field :value="compilation.name" label="Название" @input="setField('name', $event)" />

    <v-text-field :value="compilation.slug" label="Slug" @input="setField('slug', $event)" />

    <v-textarea :value="compilation.description" label="Описание" auto-grow @input="setField('description', $event)" />

    <stock-table-picker :value="compilation.tickers" :max-count="10" @input="setField('tickers', $event)" />

    <div class="mt-4 text-right">
      <v-btn v-if="isCreating" class="mr-4" @click="$emit('clear')">Сброс</v-btn>
      <v-btn type="submit" color="accent" :loading="loading">Сохранить</v-btn>
    </div>
  </v-form>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    compilation: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isCreating() {
      return !this.compilation.id;
    }
  }
};
</script>
