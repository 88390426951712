<template>
  <div>
    <h2 class="mb-4">Новая подборка сравнений</h2>

    <v-sheet class="pa-4" elevation="3">
      <compare-compilation-form
        :compilation="compilation"
        :loading="loading"
        @change-field="changeField"
        @submit="createCompilations"
        @clear="clear"
      />
    </v-sheet>
  </div>
</template>

<script>
import { editorForm } from "~/mixins/multi-editor/editor-form";
import { ApiValidationError } from "~/core/api-validation-error";

import { CompareCompilation } from "~/libs/compare-compilation/compare-compilation";

import CompareCompilationForm from "~/components/compare-compilations/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      loading: false,

      compilation: new CompareCompilation()
    };
  },
  methods: {
    async createCompilations() {
      this.loading = true;

      try {
        await this.$axios.$post("compare-compilations", this.compilation);

        this.clear();

        this.$snackbar.success("Подборка сравнений создана");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.compilation)).toHtml();

        this.$snackbar.error("Не удалось создать подборку: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.compilation = new CompareCompilation();
    },
    changeField(payload) {
      this.commitChange(this.compilation, payload);
    }
  },
  components: {
    CompareCompilationForm
  }
};
</script>
